import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Overhead Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Randy”`}</strong></p>
    <p>{`75 snatches for time (75/55`}{`#`}{`)`}</p>
    <p><em parentName="p">{`Bonus WOD:`}</em></p>
    <p>{`50 Calorie Air Dynne for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This coming Tuesday is the 4th of July.  We will have our free
holiday class at The Ville at 10:00am.  This will be our only class on
the 4th, all other classes and open gym will be cancelled so come out
and join in the fun!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      